import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FirstStep from './FirstStep';
import SecondStep from './SecondStep';

interface IAlertDialog {
  open: boolean;
  handleClose: () => void
  refreshEmployees: () => void
}

export default function AddDialog({ open, handleClose, refreshEmployees }: IAlertDialog) {
  const [step, setStep] = React.useState(1)
  const [employeeId, setEmployeeId] = React.useState<number>()

  const createUserComposite = async (employeeId: number) => {
    refreshEmployees()
    setEmployeeId(employeeId)
    setStep(2)
  }

  const handleUpdateUser = async () => {
    refreshEmployees()
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      keepMounted={false}
    >
      <DialogTitle>
        Create an Employee
      </DialogTitle>
      <DialogContent>
        {step === 1 && (
          <FirstStep handleCreateUser={createUserComposite} handleClose={handleClose} />
        )}
        {step === 2 && employeeId && (
          <SecondStep handleUpdateUser={handleUpdateUser} handleClose={handleClose} employeeId={employeeId} />
        )}

      </DialogContent>
    </Dialog >
  );
}