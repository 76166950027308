import { Button, Container } from "@mui/material"
import * as yup from 'yup';
import { useFormik } from 'formik';
import { Stack, TextField } from '@mui/material';
import { login } from "../../sdk";
import { useNavigate } from "react-router-dom";


const validationSchema = yup.object({
  email: yup
    .string()
    .email('Enter a valid email')
    .required('Email is required'),
  password: yup
    .string()
    .required('Password is required'),
});

const initialValues = {
  email: '',
  password: ''
}

function Login() {
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values, { setErrors }) => {
      try {
        const response = await login(values)
        localStorage.setItem('token', response.data.token);
        navigate("/")
      } catch (error) {
        setErrors({ email: 'Invalid email or password.' })

      }
    },
  });

  return (
    <Container maxWidth="xs" sx={{ mt: 10 }}>
      <form onSubmit={formik.handleSubmit}>
        <Stack spacing={2}>
          <TextField
            fullWidth
            id="email"
            name="email"
            label="Email"
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
          />
          <TextField
            fullWidth
            type="password"
            id="password"
            name="password"
            label="Password"
            value={formik.values.password}
            onChange={formik.handleChange}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
          />
          <Button autoFocus type="submit">
            Login
          </Button>
        </Stack>
      </form>
    </Container>
  )

}

export default Login