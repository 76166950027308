import * as React from 'react';
import * as yup from 'yup';
import Button from '@mui/material/Button';
import { useFormik } from 'formik';
import { Stack, TextField } from '@mui/material';
import { createEmployee } from '../../../../sdk';

const validationSchema = yup.object({
  first_name: yup
    .string()
    .required('This field is required')
    .min(2, 'Name too short')
    .max(50, 'Name too long'),
  last_name: yup
    .string()
    .required('This field is required')
    .min(2, 'Name too short'),
  email: yup
    .string()
    .email('Enter a valid email')
    .required('Email is required'),
});

const initialValues = {
  first_name: '',
  last_name: '',
  email: ''
}

interface IAlertDialog {
  handleCreateUser: (employeeId: number) => void
  handleClose: () => void;
}

export default function FirstStep({ handleCreateUser, handleClose }: IAlertDialog) {
  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values, { setErrors }) => {
      try {
        const response = await createEmployee(values)
        handleCreateUser(response.data.id)
      } catch (error: any) {
        setErrors(error.response.data.detail)
      }
    },
  });

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Stack spacing={2}>
          <TextField
            fullWidth
            id="first_name"
            name="first_name"
            label="First Name *"
            value={formik.values.first_name}
            onChange={formik.handleChange}
            error={formik.touched.first_name && Boolean(formik.errors.first_name)}
            helperText={formik.touched.first_name && formik.errors.first_name}
          />
          <TextField
            fullWidth
            id="last_name"
            name="last_name"
            label="Last Name *"
            value={formik.values.last_name}
            onChange={formik.handleChange}
            error={formik.touched.last_name && Boolean(formik.errors.last_name)}
            helperText={formik.touched.last_name && formik.errors.last_name}
          />
          <TextField
            fullWidth
            id="email"
            name="email"
            label="Email *"
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
          />
        </Stack>

        <Button onClick={handleClose}>Close</Button>
        <Button autoFocus type="submit">
          Next
        </Button>
      </form>
    </>
  );
}