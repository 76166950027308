import React, { useCallback, useEffect } from "react";

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Container from '@mui/material/Container';
import { Box, Button, Checkbox, IconButton, Popover, SpeedDial, SpeedDialIcon, Tooltip, Typography } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { useNavigate } from "react-router-dom";
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import DescriptionIcon from '@mui/icons-material/Description';


import AddDialog from "./AddDialog";
import { deleteEmployee, toggleEmployee, useEmployees } from "../../sdk";

function Home() {
  const navigate = useNavigate();
  const { data, mutate } = useEmployees();

  const [dialogOpen, setDialogOpen] = React.useState(false);

  const handleDelete = useCallback((id: number) => {
    deleteEmployee({ id })
    mutate()
  }, [mutate])

  const logout = useCallback(() => {
    navigate('/login')
  }, [navigate])

  const toggleActive = useCallback((employeeId: number) => {
    toggleEmployee(employeeId)
  }, [])

  const isFirefoxBrowser = navigator.userAgent.includes('Firefox');
  return (
    <Container>
      <Box sx={{ textAlign: "right", mt: 2 }}>
        <Button variant="contained" endIcon={<ExitToAppIcon />} onClick={logout}>
          Logout
        </Button>
      </Box>

      <SpeedDial
        onClick={() => setDialogOpen(true)}
        ariaLabel="SpeedDial basic example"
        sx={{ position: 'absolute', bottom: 16, right: 16, display: isFirefoxBrowser ? 'none' : 'block' }}
        icon={<SpeedDialIcon />}
      ></SpeedDial>

      {data?.results && (
        <TableContainer component={Paper} sx={{ mt: 4 }}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>First Name</TableCell>
                <TableCell align="right">Last Name</TableCell>
                <TableCell align="right">Email</TableCell>
                <TableCell align="right">Salary</TableCell>
                <TableCell align="right">Director</TableCell>
                {/* This is a bug */}
                <TableCell align="right">Plane Arrives At:</TableCell>
                <TableCell align="right">Is Active</TableCell>
                <TableCell align="right">Description</TableCell>
                <TableCell align="right">Options</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.results.map((employee) => (
                <TableRow
                  key={employee.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {employee.first_name}
                  </TableCell>
                  <TableCell align="right">{employee.last_name}</TableCell>
                  <TableCell align="right">{employee.email}</TableCell>
                  <TableCell align="right">{employee.salary}</TableCell>
                  <TableCell align="right">{employee.director?.first_name} {employee.director?.last_name}</TableCell>
                  <TableCell align="right">{employee.plane_arrival_time && (new Date(Date.parse(employee.plane_arrival_time)).toLocaleString(undefined, {
                    // TODO: This is a bug
                    timeZone: 'Canada/Pacific',
                  }))}</TableCell>
                  <TableCell><Checkbox defaultChecked={employee.is_active} onClick={() => toggleActive(employee.id)} /></TableCell>
                  <TableCell align="right">
                    <PopupState variant="popover" popupId="demo-popup-popover">
                      {(popupState) => (
                        <div>
                          <IconButton aria-label="delete" size="large" {...bindTrigger(popupState)}>
                            <DescriptionIcon />
                          </IconButton>
                          <Popover
                            {...bindPopover(popupState)}
                            anchorOrigin={{
                              vertical: 'bottom',
                              horizontal: 'center',
                            }}
                            transformOrigin={{
                              vertical: 'top',
                              horizontal: 'center',
                            }}
                          >
                            <Typography dangerouslySetInnerHTML={{ __html: employee.description || "" }} sx={{ p: 2 }}></Typography>
                          </Popover>
                        </div>
                      )}
                    </PopupState>
                  </TableCell>
                  <TableCell align="right">
                    <IconButton aria-label="delete" size="large" onClick={() => handleDelete(employee.id)}>
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer >
      )
      }
      <AddDialog open={dialogOpen} handleClose={() => setDialogOpen(false)} refreshEmployees={() => mutate()} />
    </Container >
  )
}

export default Home;
