import * as React from 'react';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { Button, FormControl, InputLabel, MenuItem, Select, Stack, TextField } from '@mui/material';
import { updateEmployee, useEmployees } from '../../../../sdk';
import { DateTimePicker } from '@mui/x-date-pickers';


const validationSchema = yup.object({
  plane_arrival_time: yup
    .string(),
  director: yup
    .string(),
  salary: yup
    .number()
    .min(740),
  description: yup
    .string()
});

const initialValues = {
  director: undefined,
  salary: undefined,
  plane_arrival_time: undefined,
  description: undefined,
}

interface IAlertDialog {
  handleClose: () => void
  employeeId: number;
  handleUpdateUser: () => void;
}

export default function AlertDialog({ handleClose, employeeId, handleUpdateUser }: IAlertDialog) {
  const { data } = useEmployees();

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      await updateEmployee(employeeId, values)
      handleUpdateUser()
      handleClose()
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Stack spacing={2}>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Director</InputLabel>
          <Select
            id="director"
            name="director"
            label="Director"
            value={formik.values.director}
            onChange={formik.handleChange}
          >
            {data?.results.map((employee) => (
              <MenuItem value={employee.id} key={employee.id}>{employee.first_name} {employee.last_name}</MenuItem>
            ))}
          </Select>
        </FormControl>

        <DateTimePicker
          label="Work shift start time"
          value={formik.values.plane_arrival_time}
          onChange={(value) => formik.setFieldValue("plane_arrival_time", value, true)}
        />

        <TextField
          fullWidth
          id="salary"
          name="salary"
          label="Salary"
          value={formik.values.salary}
          onChange={formik.handleChange}
          error={formik.touched.salary && Boolean(formik.errors.salary)}
          helperText={formik.touched.salary && formik.errors.salary}
        />

        <TextField
          multiline
          rows={4}
          fullWidth
          id="description"
          name="description"
          label="Description"
          value={formik.values.description}
          onChange={formik.handleChange}
        />
      </Stack>


      <Button onClick={handleClose}>Close</Button>
      <Button autoFocus type="submit">
        Finish
      </Button>

    </form>
  );
}