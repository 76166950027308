import { default as a } from "axios";
import useSWR from "swr";

const axios = a.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    "Content-type": "application/json",
  },
});

axios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers.authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const fetcher = (url: string) => axios.get(url).then((res) => res.data);

interface ILoginIn {
  email: string;
  password: string;
}

interface ILoginOut {
  token: string;
}

export const login = async (data: ILoginIn) => {
  return axios.post<ILoginOut>("/auth/jwt/login/", data);
};

interface ICreateEmployeeIn {
  first_name: string;
  last_name: string;
  email: string;
}

interface ICreateEmployeeOut {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
}

export const createEmployee = async (data: ICreateEmployeeIn) => {
  return axios.post<ICreateEmployeeOut>("/employees/create/", data);
};

interface IDeleteEmployeeIn {
  id: number;
}
export const deleteEmployee = async (data: IDeleteEmployeeIn) => {
  return axios.post<ICreateEmployeeOut>("/employees/delete/", data);
};

interface IEmployee {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  salary?: number;
  description?: string;
  director?: {
    id: number;
    first_name: string;
    last_name: string;
  };
  is_active: boolean;
  plane_arrival_time?: string;
}

interface useEmployeesOut {
  results: IEmployee[];
}
export const useEmployees = () => {
  return useSWR<useEmployeesOut>(`/employees`, fetcher);
};

interface IUpdateEmployeeIn {
  director?: number;
  salary?: number;
  plane_arrival_time?: string;
}

interface IUpdateEmployeeOut {
  director: number;
  salary: string;
  plane_arrival_time: string;
}

export const updateEmployee = async (id: number, data: IUpdateEmployeeIn) => {
  return axios.post<IUpdateEmployeeOut>(`/employees/${id}/update/`, data);
};

export const toggleEmployee = async (id: number) => {
  return axios.post<IUpdateEmployeeOut>(`/employees/${id}/toggle/`);
};
